import React, { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { slugify } from "../../helpers/slugify"

import * as styles from "./toc.module.scss"

const TocAuto = ({ headings, title }) => {
  const listRef = useRef(null)
  const [formattedHeadings, setFormattedHeadings] = useState([])

  useEffect(() => {
    const formatted = headings.map(item => {
      const text = item.children.map(item => item.text.trim()).join(" ")
      const level = item.style.replace(/[^\d]/g, "")
      const style = item.style
      const elementId = slugify(text)
      return { style, text, level, elementId }
    })
    setFormattedHeadings(formatted)
  }, [headings])

  useEffect(() => {
    if (listRef?.current && formattedHeadings.length) {
      let mainOL = document.createElement("ol")
      let previousNode = ""

      // build OL
      formattedHeadings.map((heading, i) => {
        let li = document.createElement("li")

        li.innerText = heading.text
        li.innerHTML = `<a href='#${heading.elementId}'>${heading.text}</a>`
        li.setAttribute("data-level", heading.level)

        // set main list elements
        if (heading.level === "2") {
          previousNode = li
          mainOL.appendChild(li)

          //if element should be nested within li prepare a new ol
        } else if (heading.level > formattedHeadings[i - 1]?.level) {
          let nestedOl = document.createElement("ol")
          nestedOl.appendChild(li)
          const items = mainOL.querySelectorAll(
            `[data-level="${heading.level - 1}"]`
          )
          const lastItem = items[items.length - 1]
          previousNode = lastItem.appendChild(nestedOl)

          //if element is same as previous
        } else if (
          heading.level === formattedHeadings[i - 1]?.level &&
          previousNode
        ) {
          previousNode.appendChild(li)

          //other cases - find last element with level one higher than current and add to the ol belonging to it
        } else {
          const items = mainOL.querySelectorAll(
            `[data-level="${heading.level - 1}"]  > ol`
          )
          const lastItem = items[items.length - 1]

          if (!!lastItem) {
            lastItem.appendChild(li)
          }
        }
      })

      listRef.current.innerHTML = ""
      listRef.current.appendChild(mainOL)
    }
  }, [formattedHeadings, listRef])

  return (
    <div className={styles.toc}>
      {title && <h3>{title}</h3>}
      <div ref={listRef}></div>
    </div>
  )
}

export default TocAuto
